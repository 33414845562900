import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {FormControl, FormGroup} from "@angular/forms";
import {BatchStatus, RetailerPayoutSummary} from "../../accounts.model";
import {DateRange} from "@angular/material/datepicker";
import {UtilsService} from "../../../../shared/services/utils.service";
import {AccountsService} from "../../accounts.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {
  CustomDateRangeChangeEventModel
} from "../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../shared/components/custom-date-filter/custom-date-range.model";
import {MatTableDataSource} from '@angular/material/table';
import {LoadingService} from '../../../../core/services/loading.service';
import * as Papa from 'papaparse';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-payout-summary',
  templateUrl: './payout-summary.component.html',
  styleUrls: ['./payout-summary.component.scss']
})
export class PayoutSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  filterForm: FormGroup;

  dataSource: MatTableDataSource<RetailerPayoutSummary> = new MatTableDataSource<RetailerPayoutSummary>();
  displayedColumns = ['paymentBatchUuid', 'processedDate', 'saleAmountInclTotal', 'paymentsExternalInclTotal', 'paymentsCollectedInclTotal', 'transactionFeeInclTotal', 'turnoverRentalFeeInclTotal', 'rollupNetBalancePayableRounded', 'processStatus'];
  exportModel = [
    {key: 'paymentBatchUuid', displayName: 'Payment Batch Id'},
    {key: 'processedDate', displayName: 'Processed Date'},
    {key: 'saleAmountInclTotal', displayName: 'Total Sales (Tax Incl)'},
    {key: 'paymentsExternalInclTotal', displayName: 'External Payments (Tax Incl)'},
    {key: 'paymentsCollectedInclTotal', displayName: 'Internal Payments (Tax Incl)'},
    {key: 'transactionFeeInclTotal', displayName: 'Transaction Fee (Tax Incl)'},
    {key: 'turnoverRentalFeeInclTotal', displayName: 'Turnover Rental Fee (Tax Incl)'},
    {key: 'rollupNetBalancePayableRounded', displayName: 'Balance Payable'},
    {key: 'processStatus', displayName: 'Processing Status'},
  ]

  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);
  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 7, TimeFrames.DAYS, 'Last 7 days'),
    new CustomDateRangeModel('2', 1, TimeFrames.MONTH, 'This month')
  ]

  batchStatuses: string[] = Object.keys(BatchStatus);
  tableTitle = 'Pay-out Summary';

  constructor(private accountsService: AccountsService,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              public loader: LoadingService) {
    this.filterForm = new FormGroup({
      payoutStatus: new FormControl(this.batchStatuses)
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
  }

  get dataSourceLength() {
    return this.dataSource.data.filter(f => f.leaseUuid).length;
  }

  loadPayoutSummary() {
    const filters = [
      {name: 'batchStatuses', val: this.filterForm.get('payoutStatus')?.value},
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ];

    this.accountsService.getPayoutHistoryForDateRange(filters)
      .subscribe({
        next: value => {
          this.dataSource.data = value
        },
        error: err => console.log(err),
      });
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    if (event.dateRange.start && event.dateRange.end) {
      this.dateRange = event.dateRange;
      this.loadPayoutSummary();
    }
  }

  filterPayoutSummary(): void {
    this.loadPayoutSummary();
  }

  exportToCsv() {
    const dataWithDisplayLabels = this.dataSource.data.map(
      (item: any) => {
        const newItem: any = {};
        this.exportModel.forEach((column) => {
          newItem[column.displayName.toUpperCase()] = item[column.key];
        });
        return newItem;
      }
    );

    const csv = Papa.unparse(dataWithDisplayLabels);
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);

    this.downloadFile(url, `${this.tableTitle}-${new Date().toISOString()}.csv`);

  }

  exportToExcel() {

    const dataWithDisplayLabels = this.dataSource.filteredData.map(
      (item: any) => {
        const newItem: any = {};
        this.exportModel.forEach((column) => {
          newItem[column.displayName.toUpperCase()] = item[column.key];
        });
        return newItem;
      }
    );

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataWithDisplayLabels);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, `${this.tableTitle}-${new Date().toISOString()}.xlsx`);
  }

  exportToPdf() {

    const doc = new jsPDF('landscape', 'pt', 'a4');
    const columns = this.exportModel.map((col) => col.displayName);
    const body = this.dataSource.filteredData.map((row: any) =>
      this.exportModel.map((col) => row[col.key]?.toString() || '')
    );
    autoTable(doc, {
      head: [columns],
      body: body,
    });
    doc.save(`${this.tableTitle}-${new Date().toISOString()}.pdf`);

  }

  private downloadFile(url: string, fileName: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
