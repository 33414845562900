import {Component, OnInit, ViewChild} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {MatSelectChange} from '@angular/material/select';
import {MatSidenav} from '@angular/material/sidenav';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {lastValueFrom, Observable} from 'rxjs';
import {ThemeService} from "../themes/theme-service.service";
import {LocationOffering} from './core/model/location-offering.interface';
import {Retailer} from './core/model/retailer.model';
import {FlattenedPortalUserFeatures} from './core/model/user-access.model';
import {LandlordsService} from './core/services/landlords.service';
import {LoadingService} from "./core/services/loading.service";
import {MenuSection, MenuService} from './core/services/menu.service';
import {AuthenticationService} from './core/services/security/authentication.service';
import {CurrentContextService} from './core/services/security/current-context.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'soko-portal';
  navLogo: string = '../assets/icons/default-nter-logo.svg';
  public login: Observable<boolean>;
  public offeringSet: Observable<boolean>;
  isLoggedIn: boolean = false;
  isOfferingSet: boolean = false;
  @ViewChild("sidenav") sidenav!: MatSidenav;
  selectedOffering: LocationOffering | null = null;
  availableOfferings: LocationOffering[] = [];
  menuItems: MenuSection[] = [];
  currentRetailer: Retailer | null = null;

  loading$ = this.loader.loading$;
  dashboardVisible: boolean = true;
  dashboardFeatures: string [] = ["dashboards_portal_retailer_view_best_month",
    "dashboards_portal_retailer_view_basket_size",
    "dashboards_portal_retailer_view_inventory_forecast",
    "dashboards_portal_retailer_view_best_sellers",
    "dashboards_portal_retailer_view_busiest_times",
    "dashboards_portal_retailer_view_poor_sellers",
    "dashboards_portal_retailer_view_overall_performance",
    "dashboards_portal_retailer_view_daily_transaction",
    "dashboards_portal_retailer_view_rent_performance",]

  sideNavAndMenuExcludedUrls: string[] = [
    'forgot-password',
    'forgot-password/check-your-email',
    'forgot-password/password-reset',
    'forgot-password/verify',
    'apply',
    'account/verify',
    'login',
    'join',
    '/user-profile/curation/',
    '/apply?embedKey=',
    '/apply/embed?embedKey='
  ]

  hideToolbarUrls: string[] = [
    '/apply/embed?embedKey='
  ]

  constructor(private authService: AuthenticationService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private router: Router,
              private contextService: CurrentContextService,
              private menuService: MenuService,
              private landlordService: LandlordsService,
              private themeService: ThemeService,
              private loader: LoadingService
  ) {
    this.login = this.authService.isLoggedIn;
    this.matIconRegistry.addSvgIcon('back-arrow-button', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/back-arrow-button.svg'));
    this.matIconRegistry.addSvgIcon('company-info-settings', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/company-info-settings.svg'));
    this.matIconRegistry.addSvgIcon('billing-payment-settings', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/billing-payment-settings.svg'));
    this.matIconRegistry.addSvgIcon('user-access-settings', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/user-access-settings.svg'));

    this.menuItems = menuService.getMenu();
    this.dashboardVisible = this.hasAnyFeature(this.dashboardFeatures);
    this.filterMenuItemsOnGrants();

    this.offeringSet = this.contextService.isOfferingSet();

    this.offeringSet.subscribe((isOfferingSet: boolean) => {
      this.isOfferingSet = isOfferingSet;
      if (isOfferingSet && this.isLoggedIn) {
        this.filterMenuItemsOnGrants();
        this.dashboardVisible = this.hasAnyFeature(this.dashboardFeatures);

        if (this.contextService.getCurrentOffering()!.locationCode.toLowerCase() == 'sook-mall-of-africa') {
          this.menuItems = this.menuItems.filter(item => {
            return item.label != 'Accounts'
          });
          this.dashboardVisible = false;
        }

        this.themeService.setThemeColors(this.contextService.getCurrentOffering()!);
        if (this.sidenav && this.canSideNavOpen()) {
          this.sidenav.open().catch((err: string) => {
            console.log(err)
          });
        }
      } else {
        if (this.sidenav) {
          this.sidenav.close().catch((err: string) => console.log(err));
        }
      }
    });

    this.login.subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;

      if (this.isLoggedIn) {
        this.contextService.getAccessibleOfferingsAsObject(true)
          .then((offerings: LocationOffering[]) => {
            this.availableOfferings = offerings;
          });
      }

      let retailerLogoPic: string | null = this.contextService.getRetailerLogoPic();
      if (retailerLogoPic != null) {
        this.navLogo = retailerLogoPic;
      } else {
        this.navLogo = '../assets/icons/default-nter-logo.svg';
      }
    });


    this.selectedOffering = this.contextService.getCurrentOffering();
    this.contextService.getOfferingChangeEvent()
      .subscribe((offering: LocationOffering) => {
        if (this.isLoggedIn) {
          this.filterMenuItemsOnGrants();
          this.dashboardVisible = this.hasAnyFeature(this.dashboardFeatures);
          this.selectedOffering = offering;
          this.themeService.setThemeColors(offering);
        }
      });

    this.contextService.getClearCurrentOfferingEvent()
      .subscribe((clear: boolean) => {
        if (clear) {
          this.selectedOffering = null;
          this.themeService.setThemeColors(null);
          this.sidenav.close().catch((error: string) => console.log(error));
          this.router.navigate([`${this.contextService.getCurrentLocationCode()}/home`])
            .catch(error => {
              console.error(error);
            });
        }
      })
  }

  toggleSidenav() {
    if (this.isLoggedIn && this.contextService.getCurrentOffering() !== null) {
      this.sidenav.toggle();
    }
  }

  ngOnInit(): void {
    this.login = this.authService.isLoggedIn;
    setTimeout(() => this.hideSupportWidget(), 500);
  }

  goToSeeOfferings(): void {
    this.contextService.sendClearCurrentOfferingEvent();
    this.sidenav.close().catch((err: string) => console.log(err))
  }

  get canNavbarShow(): boolean {
    const isMatch = this.sideNavAndMenuExcludedUrls.some(urlToMatch => this.router.url.includes(urlToMatch));
    if (isMatch) this.sidenav.close();
    return !!this.selectedOffering && this.isLoggedIn && !isMatch;
  }

  get isApplicationScreen() {
    return this.router.url.includes('/apply?embedKey=') || this.router.url.includes('/apply/embed?embedKey=');
  }

  changeSelectedOfferingFromNav($event: MatSelectChange): void {
    lastValueFrom(this.landlordService.getOffering($event.value))
      .then((offering) => {
        this.contextService.setCurrentOffering(offering);
        this.router.navigate([`${this.contextService.getCurrentLocationCode()}/${offering.offeringCode}/home`])
          .catch((error: string) => {
            console.log(error)
          });
      });
  }

  canSideNavOpen(): boolean {
    const isMatch = this.sideNavAndMenuExcludedUrls.some(urlToMatch => this.router.url.includes(urlToMatch));
    return this.isLoggedIn && this.isOfferingSet && !isMatch;
  }

  disableChangeOffering(): boolean {
    return this.availableOfferings.length <= 1;
  }

  activeRoute(route: string): boolean {
    return this.router.isActive(route, true);
  }

  changeRoute(url: string, params: object): void {
    this.router.navigate([url], {queryParams: params})
      .catch((error) => {
        console.log(error);
      });
  }

  embeddedPage(): boolean {
    return this.hideToolbarUrls.some(urlToMatch => this.router.url.includes(urlToMatch));
  }

  get getDate(): any {
    return new Date();
  }

  isSectionSelected(url: string): boolean {
    return this.router.url == `/${url}`;
  }

  contentPages() {
    const contentUrls = ['dashboards', 'settings', 'leases', 'accounts', 'forms'];
    let matching = false;
    contentUrls.forEach(ea => {
      matching = matching || this.router.url.includes(`/${ea}`)
    })
    return matching
  }

  getSubsections(section: MenuSection) {
    if (this.contextService.getCurrentOffering() && !(this.contextService.getCurrentOffering()!.companyName.toLowerCase()).includes('hyprop')) {
      return section.subSections!.filter(f => f.label != 'Interest');
    }
    return section.subSections;
  }

  showLoginInNavBar(): boolean {
    return this.router.url.includes('/retail-spaces');
  }

  goToSignUp(): void {
    this.router.navigate([`/${this.contextService.getCurrentLocationCode()}/join`])
      .catch((error: string) => {
        console.log(error);
      });
  }

  get publicListingsPages() {
    return this.router.url.includes('retail-spaces');
  }

  get publicApplicationForm() {
    return this.router.url.includes('apply');
  }

  private hasFeatureCode(featureCode: string): boolean {
    let flattenedUserGrants: FlattenedPortalUserFeatures | null = this.contextService.getFlattenedUserGrants();
    let currentOffering: LocationOffering | null = this.contextService.getCurrentOffering();
    if (flattenedUserGrants && currentOffering && flattenedUserGrants.features && flattenedUserGrants.features.size > 0) {
      let offeringFeatures = flattenedUserGrants.features.get(currentOffering.offeringUuid)!;
      return offeringFeatures.includes(featureCode);
    }
    return false;
  }

  goToLogin(url?: string): void {
    const returnUrl = this.contextService.getCurrentLocationCode()! + url;
    this.contextService.sendClearCurrentOfferingEvent();
    this.router.navigate([`${this.contextService.getCurrentLocationCode()}/login`, {returnUrl}])
      .catch((error: string) => {
        console.log(error);
      });
  }

  private hasAnyFeature(featureCodeList: string[]): boolean {
    let flattenedUserGrants: FlattenedPortalUserFeatures | null = this.contextService.getFlattenedUserGrants();
    let currentOffering: LocationOffering | null = this.contextService.getCurrentOffering();
    if (flattenedUserGrants && currentOffering && flattenedUserGrants.features && flattenedUserGrants.features.size > 0) {
      let offeringFeatures = flattenedUserGrants.features.get(currentOffering.offeringUuid)!;
      if (offeringFeatures && offeringFeatures.length > 0) {
        return offeringFeatures.some((item) => featureCodeList.includes(item));
      }
    }
    return false;
  }

  private filterMenuItemsOnGrants(): void {
    let flattenedUserGrants: FlattenedPortalUserFeatures | null = this.contextService.getFlattenedUserGrants();
    let currentOffering: LocationOffering | null = this.contextService.getCurrentOffering();
    if (flattenedUserGrants && currentOffering && flattenedUserGrants.features && flattenedUserGrants.features.size > 0) {
      let offeringFeatures = flattenedUserGrants.features.get(currentOffering.offeringUuid)!;
      if (offeringFeatures) {
        this.menuItems = this.filterMenuSectionsByFeatureCodes(this.menuService.getMenu(), offeringFeatures);
      } else {
        this.menuItems = [];
      }
    } else {
      this.menuItems = [];
    }
  }

  private filterMenuSectionsByFeatureCodes(menuSections: MenuSection[], featureCodes: string[]): MenuSection[] {
    const filteredMenuSections: MenuSection[] = [];

    for (const menuSection of menuSections) {
      if (menuSection.featureCodes && featureCodes) {
        const hasMatch = menuSection.featureCodes.some(featureCode => featureCodes.some(userFeature => userFeature.includes(featureCode)));
        if (hasMatch) {
          filteredMenuSections.push(menuSection);
        }
      }

      if (menuSection.subSections) {
        const filteredSubSections = this.filterMenuSectionsByFeatureCodes(menuSection.subSections, featureCodes);
        if (filteredSubSections.length > 0) {
          menuSection.subSections = filteredSubSections;
          filteredMenuSections.push(menuSection);
        }
      }
    }

    return filteredMenuSections;
  }

  hideSupportWidget() {
    const widget = document.getElementById('jsd-widget');
    if (widget) {
      if (this.isApplicationScreen) {
        widget.style.display = 'none';
      } else {
        widget.style.display = 'block';
      }
    }
  }
}
