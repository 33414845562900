<div class="page-content page-padding" fxLayout="column">
  <div fxFlexOffset="20px">
    <div fxLayout="row">
      <span class="subtitle-default">Transaction Schedule</span>
    </div>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
      <span class="heading-default">All transactions</span>

      <button
        [disabled]="!!(loader.loading$ | async)"
        [matMenuTriggerFor]="mainOptionsMenu"
        mat-icon-button
        matTooltip="Table options"
      >
        <mat-icon class="crud-table-main-toolbar-icon">more_vert</mat-icon>
      </button>
      <mat-menu #mainOptionsMenu="matMenu">
        <!-- Export to CSV -->
        <button (click)="exportToCsv()" mat-menu-item>
          <mat-icon>file_download</mat-icon>
          <span>Export to CSV</span>
        </button>

        <!-- Export to Excel -->
        <button (click)="exportToExcel()" mat-menu-item>
          <mat-icon>file_download</mat-icon>
          <span>Export to Excel</span>
        </button>

        <!-- Export to PDF -->
        <button (click)="exportToPdf()" mat-menu-item>
          <mat-icon>file_download</mat-icon>
          <span>Export to PDF</span>
        </button>

      </mat-menu>
    </div>
    <form [formGroup]="filterForm" fxFlexOffset="20px">
      <div fxLayout="row" fxLayoutAlign="space-between center">

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
            <mat-label>Point-of-sale Status</mat-label>
            <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="posStatus">
              <mat-option class="custom-select-field-option"  *ngFor="let status of saleStatuses" [value]="status">{{utils.displayStatus(status)}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always" *ngIf="transactionStatuses">
            <mat-label>Pay-out Status</mat-label>
            <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="processingStatus">
              <mat-option class="custom-select-field-option"  *ngFor="let status of transactionStatuses" [value]="status.val">{{status.label}}</mat-option>
            </mat-select>
          </mat-form-field>
          <button (click)="filterData()" [disabled]="!!(loader.loading$ | async)" color="primary" mat-flat-button>
            Filter
          </button>
        </div>
        <app-custom-date-filter fxFlexAlign="end" [customDateRangePresets]="dateFilters"
                                [showBorder]="false"
                                (dateRange)="getDateRange($event)"
                                [initialPreset]="'1'"
                                [showClearButton]="true">

        </app-custom-date-filter>
      </div>

    </form>

    <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px" fxFlex="60">
      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Transactions </th>
      </ng-container>

      <ng-container matColumnDef="transactionInvoiceNumber">
        <th mat-header-cell *matHeaderCellDef> Transaction ID </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{ utils.displayString(element.transactionInvoiceNumber, 12) }} </td>
      </ng-container>

      <ng-container matColumnDef="transactionDate">
        <th *matHeaderCellDef mat-header-cell> Sale Date</th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ dateUtils.displayDateWithTime(element.transactionDate) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="productName">
        <th *matHeaderCellDef mat-header-cell> Product Name</th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell> {{ utils.displayString(element.productName, 20) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentMethods">
        <th *matHeaderCellDef mat-header-cell> Payment Methods</th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell> {{ utils.displayString(element.paymentMethods, 20) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th *matHeaderCellDef mat-header-cell> Quantity</th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor" mat-cell> {{ element.quantity }}</td>
      </ng-container>

      <ng-container matColumnDef="saleAmountIncl">
        <th mat-header-cell *matHeaderCellDef> Total Sales </th>
        <td *matCellDef="let element" class="custom-table-cell"
            mat-cell> {{ utils.displayRandAmountWithDecimal(element.saleAmountIncl) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="transactionFeeIncl">
        <th mat-header-cell *matHeaderCellDef> Transaction Fee </th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell> {{ utils.displayRandAmountWithDecimal(element.transactionFeeIncl) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="turnoverRentalFeeIncl">
        <th mat-header-cell *matHeaderCellDef> Rental Fee </th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell> {{ utils.displayRandAmountWithDecimal(element.turnoverRentalFeeIncl) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="netBalancePayableRetailerInclRounded">
        <th *matHeaderCellDef mat-header-cell> Balance Payable</th>
        <td *matCellDef="let element" class="custom-table-cell btn-cursor"
            mat-cell> {{ utils.displayRandAmountWithDecimal(element.netBalancePayableRetailerInclRounded) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="posStatus">
        <th mat-header-cell *matHeaderCellDef> Point-of-sale Status </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayStatus(element.pointOfSaleStatus)}} </td>
      </ng-container>

      <ng-container matColumnDef="processingStatus">
        <th mat-header-cell *matHeaderCellDef> Pay-out Status </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayStatus(element.processingStatus)}} </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource.totalElements"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

  </div>
  <div class="info-card-dark" fxFlexOffset="30px" fxLayout="column" fxLayoutGap="20px">
    <span class="heading-bold">Notes</span>
    <span class="body-1-regular-2">Point of Sale Status: The sale status as provided by the POS software</span>
    <span class="body-1-regular-2">Sale Status: The status of the sale as per the platform processing. The platform only processes sales in the "CLOSED" status</span>
  </div>
</div>
