<mat-sidenav-container>
  <mat-sidenav #sidenav [fixedBottomGap]="0" [fixedInViewport]="true"
               [fixedTopGap]="0"
               [opened]="canNavbarShow"
               class="main-sidenav"
               mode="side"
  >
    <mat-toolbar class="sidenav-toolbar nav-bar-height" fxLayout="row" fxLayoutAlign="space-between center"
                 *ngIf="!embeddedPage()">
      <!-- Drawer  -->
      <img (click)="toggleSidenav()" [src]="navLogo"
           alt="owner-logo"
           class="owner-logo btn-cursor" *ngIf="canSideNavOpen()">
      <button (click)="toggleSidenav()" class="drawer-button" mat-icon-button *ngIf="canSideNavOpen()">
        <mat-icon svgIcon="back-arrow-button"></mat-icon>
      </button>
    </mat-toolbar>
    <div fxLayout="column" fxLayoutAlign="start center" *ngIf="isLoggedIn">
      <!-- Drawer  Content going down the opened menu-->
      <mat-form-field appearance="outline" fxFlexOffset="20px">
        <mat-select [value]="selectedOffering?.offeringUuid"
                    (selectionChange)="changeSelectedOfferingFromNav($event)" [disabled]="disableChangeOffering()">
          <ng-container *ngFor="let offering of availableOfferings">
            <mat-option [value]="offering.offeringUuid"
                        >{{offering.offeringName}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <div class="sidenav-content full-width" fxLayout="column" fxLayoutAlign="start center" fxFlexOffset="35px">
        <div class="dashboard-container pb-2 full-width" fxLayout="column" fxLayoutAlign="start center" *ngIf="dashboardVisible">
          <button mat-flat-button fxLayoutAlign="start center"
                  (click)="changeRoute('/dashboards', {})"
                  [ngClass]="isSectionSelected('dashboards') ? 'sidenav-title-active' : 'sidenav-title'">
            <div class="dashboard-nav-icon" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <mat-icon class="material-icons-outlined">bar_chart</mat-icon>
                <div class="sidenav-heading">Dashboards</div>
              </div>
            </div>
          </button>
        </div>
        <mat-accordion multi class="full-width">
          <mat-expansion-panel class="sidenav-section"
                               [(expanded)]="item.selected"
                               *ngFor="let item of menuItems">
            <mat-expansion-panel-header class="subheading-default">{{item.label}}</mat-expansion-panel-header>

            <div fxLayout="column" fxLayoutAlign="start center">

              <div *ngFor="let section of getSubsections(item)">
                <button mat-flat-button fxLayoutAlign="start center"
                        (click)="changeRoute(section.url!, section.urlParams!)"
                        [ngClass]="isSectionSelected(section.url!) ? 'sidenav-title-active' : 'sidenav-title'" style="place-content: start">

                  <div fxLayout="row" class="menu-section-title" fxLayoutAlign="start center" fxLayoutGap="15px" style="height: 50px !important;">
                    <mat-icon class="material-icons-outlined" *ngIf="!section.iconSVG">{{section.icon}}</mat-icon>
                    <mat-icon class="svg-icon" svgIcon="{{section.icon}}" *ngIf="section.iconSVG"></mat-icon>
                    <div class="sidenav-heading">{{section.label}}</div>
                  </div>
                </button>
              </div>

            </div>

          </mat-expansion-panel>

        </mat-accordion>
      </div>
    </div>

    <footer class="drawer-footer full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <div class="support-button full-width btn-cursor" (click)="changeRoute('/support', {})" fxLayout="row"
           fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon class="support-icon material-icons-outlined">help_outline</mat-icon>
        <div class="body-1-primary">Support</div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="body-2-default pb-1">© Nter {{getDate | date: 'yyyy'}}. All rights reserved.</div>
        <app-footer-links [showLinksOnly]="true"></app-footer-links>
      </div>
    </footer>
  </mat-sidenav>


  <mat-sidenav-content>

    <mat-toolbar class="full-width px-4"
                 *ngIf="!embeddedPage()"
                 [class.main-toolbar-logged-in]="isLoggedIn || publicListingsPages || publicApplicationForm"
                 [class.main-toolbar-grey]="contentPages() || !!selectedOffering"
                 fxLayout="row" fxLayoutAlign="space-between center">
      <!-- Header where tab bar is -->
      <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="isLoggedIn === false ; else loggedInMenu" class="pe-3" fxLayout="row" fxLayoutAlign="center center"
             fxLayoutGap="15px"> <!-- Icons and logo logged out -->
          <img (click)="goToLogin('/home')" [src]="navLogo"
               alt="owner-logo" class="owner-logo btn-cursor">
          <div class="flex-column" fxLayoutAlign="center start">
            <div class="heading-default">Retailer self-service portal</div>
            <div class="body-2-default">powered by nter</div>
          </div>
        </div>
        <ng-template #loggedInMenu>
          <div *ngIf="!sidenav.opened && selectedOffering" class="pe-3" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
            <button (click)="toggleSidenav()" class="drawer-button mat-elevation-z1" mat-icon-button
                    *ngIf="canSideNavOpen()">
              <mat-icon style="transform: rotate(180deg)" svgIcon="back-arrow-button"></mat-icon>
            </button>
          </div>
        </ng-template>

        <div (click)="goToSeeOfferings()" *ngIf="canNavbarShow; else navBarSpacer"
             fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px"
             class="full-width nav-tab-bar nav-bar-height btn-cursor" style="padding-left: 35px">
          <mat-icon aria-hidden="false" aria-label="home-icon" class="material-icons-outlined">home</mat-icon>
          <span class="body-1-regular-2">Go to all retail spaces home page to see all available retail offerings</span>
        </div>

        <ng-template #navBarSpacer>
          <div class="full-width nav-tab-bar"></div>
        </ng-template>


        <div class="menu-vertical-divider"></div>
        <div *ngIf="isLoggedIn else loginNavBarTemplate">
          <app-user-profile-popover></app-user-profile-popover>
        </div>
        <ng-template #loginNavBarTemplate>
          <div *ngIf="showLoginInNavBar()" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <button (click)="goToLogin('/home')" class="button-secondary" mat-flat-button>
              <div class="button-text"><u>Log in</u></div>
            </button>
            <button (click)="goToSignUp()" class="button-primary" mat-flat-button>
              <div class="button-text">Sign Up</div>
            </button>
          </div>
        </ng-template>
      </div>
    </mat-toolbar>

    <mat-progress-bar mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>

    <mat-sidenav-container class="sidenav-container"
                           [class.sidenav-container-no-toolbar]="embeddedPage()">
      <mat-sidenav-content [class.hide-scroll-bar]="isApplicationScreen">
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-sidenav-content>
</mat-sidenav-container>
