import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDrawer} from "@angular/material/sidenav";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BehaviorSubject, lastValueFrom} from 'rxjs';
import {environment} from "../../../../environments/environment";
import {MatSelectOption} from '../../../core/interfaces/mat-select-option.interface';
import {LocationOffering, LocationOfferingFilterRequest} from '../../../core/model/location-offering.interface';
import {PlatformOfferingTypesModel} from '../../../core/model/platform-offering-types.model';
import {LandlordsService} from '../../../core/services/landlords.service';
import {OfferingApplicationService} from '../../../core/services/offering-application.service';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {UtilsService} from '../../services/utils.service';
import {OfferingListingComponent} from "./offering-listing/offering-listing.component";

@Component({
  selector: 'app-offering-carousel',
  templateUrl: './offering-carousel.component.html',
  styleUrls: ['./offering-carousel.component.scss']
})
export class OfferingCarousel implements OnInit, OnChanges {
  @ViewChild("listingDrawer") listingDrawer!: MatDrawer;

  @Input() isOfferingSelected: boolean = false;
  @Output() isOfferingSelectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() selectedOffering: LocationOffering | null = null;
  @Output() selectedOfferingChange: EventEmitter<LocationOffering> = new EventEmitter<LocationOffering>();
  @Input() isPublicRoute: boolean = true;
  @Input() offeringNameSearchVal?: string;

  allOfferings: LocationOffering[] = [];
  @Input() offeringNameFilter?: string;
  @Input() changeContext: boolean = true;
  @Input() showSelectionOnHover: boolean = false;
  accessibleOfferings: string[];
  applicationStatus: { offeringUuid: string, isNew: boolean }[] = [];
  offeringTypes: PlatformOfferingTypesModel[] = [];
  offeringTypesFilter: string[] = [];
  ALL_RETAILERS_FILTER_CODE = 'ALL_RETAIL_SPACES';
  allOfferingsSubject: BehaviorSubject<LocationOffering[]> = new BehaviorSubject<LocationOffering[]>([]);
  regionFilterOptions: MatSelectOption[] = [
    {name: 'Gauteng', value: 'Gauteng'},
    {name: 'Western Cape', value: 'Western Cape'},
    {name: 'Eastern Cape', value: 'Eastern Cape'},
    {name: 'Free State', value: 'Free State'},
    {name: 'KwaZulu-Natal', value: 'KwaZulu-Natal'},
    {name: 'Limpopo', value: 'Limpopo'},
    {name: 'Mpumalanga', value: 'Mpumalanga'},
    {name: 'Northern Cape', value: 'Northern Cape'},
    {name: 'North West', value: 'North West'}
  ];
  regionFormGroup: FormGroup;

  highlightedOffering: LocationOffering | null = null;
  locationCode: string | null;
  googleMapsLoaded = false;


  constructor(private contextService: CurrentContextService,
              public utilsService: UtilsService,
              private router: Router,
              private offeringApplicationService: OfferingApplicationService,
              private activatedRoute: ActivatedRoute,
              private landlordsService: LandlordsService) {

    this.accessibleOfferings = contextService.getAccessibleOfferingsFromToken()

    this.locationCode = this.contextService.getCurrentLocationCode();

    this.regionFormGroup = new FormGroup({
      regions: new FormControl([])
    });

    lastValueFrom(this.landlordsService.getOfferingTypes())
      .then((res: PlatformOfferingTypesModel[]) => {
        this.offeringTypes = res;
      });

    this.regionFormGroup.valueChanges.subscribe(() => {
      this.loadOfferings(false);
    });

    this.accessibleOfferings
      .forEach((offeringUuid: string) => {
        lastValueFrom(this.offeringApplicationService.getRetailerApplicationStatusForOffering(offeringUuid))
          .then((status: string) => {
            this.applicationStatus.push({
              offeringUuid: offeringUuid,
              isNew: (status.toLowerCase() === 'new' || status.toLowerCase() === 'pending')
            });
          });
      });

    this.loadOfferings(false);

  }

  ngOnInit(): void {

    let offeringListingUuid: string | null = this.activatedRoute.snapshot.paramMap.get('offeringListingUuid');

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let shouldLoad: boolean = false;
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        let shouldLoad: boolean = false;
        if (params.hasOwnProperty('offeringTypes')) {
          this.offeringTypesFilter = params['offeringTypes'].split(',');
          this.offeringTypesFilter = this.offeringTypesFilter.map(offeringType => {
            return offeringType.toUpperCase();
          });
        } else {
          this.offeringTypesFilter = [this.ALL_RETAILERS_FILTER_CODE];
        }
        if (shouldLoad) {
          this.loadOfferings(false);
        }
      });

      if (params.hasOwnProperty('searchVal')) {
        this.offeringNameSearchVal = params['searchVal'];
        shouldLoad = true;
      }

      if (params.hasOwnProperty('regions')) {
        let regions: string[] = params['regions'].split(',');
        regions = regions.map((region) => {
          return this.utilsService.toTitleCase(region);
        });
        this.regionFormGroup.get('regions')!.setValue(regions);
        shouldLoad = true;
      }

      if (shouldLoad) {
        this.loadOfferings(false);
      }
    });

    if (offeringListingUuid) {
      this.allOfferingsSubject.subscribe((offerings) => {
        let locationOfferings: LocationOffering[] = offerings.filter((offering) => {
          if (offering.offeringListing) {
            return (offering.offeringListing.uuid! == offeringListingUuid);
          }
          return false;
        });
        if (locationOfferings.length == 1) {
          this.toggleListingDrawer(true, locationOfferings[0]);
        }
      });

    }

    this.loadGoogleMapsAPI();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('offeringNameSearchVal')) {
      this.offeringNameSearchVal = (changes['offeringNameSearchVal'].currentValue) ? changes['offeringNameSearchVal'].currentValue : "";
      if (this.offeringNameSearchVal && this.offeringNameSearchVal.length >= 3) {
        this.loadOfferings(false);
      } else if (changes['offeringNameSearchVal'].previousValue && changes['offeringNameSearchVal'].previousValue.length >= 1 && changes['offeringNameSearchVal'].currentValue.length === 0) {
        this.loadOfferings(false);
      }
    }
  }

  hasSubmittedApplicationForOffering(offeringUuid: string): boolean {
    let filter: { offeringUuid: string; isNew: boolean }[] = this.applicationStatus
      .filter((statusObject) => {
        return (statusObject.offeringUuid.toLowerCase() === offeringUuid.toLowerCase())
      });
    if (filter[0]) {
      return !filter[0].isNew;
    }
    return true;
  }


  enterOfferingPortal(offering: LocationOffering): void {
    if (this.isPublicRoute) {
      this.router.navigate([`${this.locationCode}/${offering.offeringCode}/home`], {queryParams: {selectedOffering: offering.uuid}})
        .catch((error: string) => {
          console.log(error);
        });
    } else {
      if (this.changeContext) {
        this.contextService.setCurrentOffering(offering)
        this.router.navigate([`${this.locationCode}/${offering.offeringCode}/home/`])
          .catch((error: string) => {
            console.log(error);
          });
      }
      this.selectedOffering = offering;
      this.selectedOfferingChange.emit(offering);
      this.isOfferingSelected = true;
      this.isOfferingSelectedChange.emit(true)
    }
  }

  applyForOffering(offering: LocationOffering): void {
    let urlToOpen = `${environment.apiPortal}/${this.contextService.getCurrentLocationCode()}/${offering.offeringCode}/apply?embedKey=${offering.embedKey}`;
    window.open(urlToOpen, '_blank');
  }

  canEnterPortal(uuid: string): boolean {
    return this.accessibleOfferings.some(offering => offering.toLowerCase() == uuid.toLowerCase());
  }

  hasOfferingListing(offering: LocationOffering): boolean {
    return (offering.offeringListing !== null);
  }

  filterOfferingsByType(): void {
    if (this.offeringTypesFilter.includes(this.ALL_RETAILERS_FILTER_CODE)) {
      let index: number = this.offeringTypesFilter.indexOf(this.ALL_RETAILERS_FILTER_CODE);
      this.offeringTypesFilter.splice(index, 1);
    }
    this.loadOfferings(false);
  }

  filterByAllOfferings(): void {
    this.offeringTypesFilter = [this.ALL_RETAILERS_FILTER_CODE];
    this.loadOfferings(false);
  }

  private loadOfferings(filter: boolean): void {
    const submitFilters = Object.assign([], this.offeringTypesFilter);
    if (submitFilters && submitFilters.includes(this.ALL_RETAILERS_FILTER_CODE)) {
      let index: number = submitFilters.indexOf(this.ALL_RETAILERS_FILTER_CODE);
      submitFilters.splice(index, 1);
    }

    let filterRequest: LocationOfferingFilterRequest = {
      offeringTypes: submitFilters,
      offeringName: (this.offeringNameSearchVal && this.offeringNameSearchVal.length > 3) ? this.offeringNameSearchVal : "",
      regions: this.regionFormGroup.get('regions')?.value
    }

    this.contextService.getAccessibleOfferingsAsObject(filter, filterRequest)
      .then((offerings: LocationOffering[]) => {
        this.allOfferings = offerings.map((offering: LocationOffering) => {
          if (offering.bannerImage!.startsWith('https://ips.') && !offering.bannerImage!.endsWith('&height=260')) {
            offering.bannerImage += '&height=260';
            console.log("Updated: " + offering.bannerImage);
          }
          return offering;
        });
        this.allOfferings.filter((offering: LocationOffering) => {
          return !this.accessibleOfferings.includes(offering.offeringUuid);
        }).forEach((offering: LocationOffering) => {
          this.applicationStatus.push({offeringUuid: offering.offeringUuid, isNew: true});
        });

        this.allOfferings = this.allOfferings.filter((offering: LocationOffering) => {
          return offering.locationCode && offering.locationCode.toLowerCase() === this.locationCode?.toLowerCase();
        });

        this.allOfferingsSubject.next(this.allOfferings);
      });
  }

  toggleListingDrawer(open: boolean, offering?: LocationOffering) {
    if (open && offering) {
      if (this.hasOfferingListing(offering)) {
        this.highlightedOffering = offering;
        this.listingDrawer.toggle(open);
      }
    } else {
      this.highlightedOffering = null;
      this.listingDrawer.toggle(open);
    }
  }

  loadGoogleMapsAPI(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Debug: Log the google object
      console.log('Current google object:', typeof google !== 'undefined' ? google : 'undefined');

      if (typeof google !== 'undefined') {
        this.googleMapsLoaded = true;
        console.log('Google Maps API already loaded.');
        resolve();
      } else {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places`;

        script.onload = () => {
          this.googleMapsLoaded = true;
          console.log('Google Maps API loaded successfully.');
          resolve();
        };

        script.onerror = (err) => {
          console.error('Error loading Google Maps API:', err);
          reject(err);
        };

        document.body.appendChild(script);
      }
    });
  }

  openDrawer(listing: OfferingListingComponent) {
    listing.onDrawerOpen(this.googleMapsLoaded);
  }

}
