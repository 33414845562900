<div class="listing-drawer py-3 px-4 full-width" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="15px">
  <div class="full-width close-button-container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <button (click)="toggleDrawer.emit(false)" class="drawer-button" mat-icon-button>
      <mat-icon style="transform: rotate(180deg)" svgIcon="back-arrow-button"></mat-icon>
    </button>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <button (click)="apply.emit()" class="button-outline-white" mat-flat-button style="width: 90px;">
        <div class="button-text">Apply</div>
      </button>
      <button (click)="enter.emit()" *ngIf="!isPublic" class="button-primary" mat-flat-button style="width: 90px;">
        <div class="button-text">Portal</div>
      </button>
    </div>
  </div>

  <div class="full-width" fxLayout="column" fxLayoutGap="15px" *ngIf="offering">
    <div class="grey-card" fxLayout="row" fxLayoutAlign="start center">
      <div class="location-icon"><mat-icon svgIcon="location-away"></mat-icon></div>
      <span class="heading-bold" fxFlexOffset="20px">{{offering.offeringName}}</span>
    </div>

    <div class="grey-card p-3" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span class="heading-regular">Listing details</span>
        <div [cdkCopyToClipboard]="getUrlToListing()"
             (cdkCopyToClipboardCopied)="openSnackBar('Copied to clipboard')"
             class="icon-text-container btn-cursor" fxLayoutGap="10px">
          <mat-icon class="material-icons-outlined">share</mat-icon>
          <span>Share</span>
        </div>
      </div>
      <div class="heading-default p-3" fxFlexOffset="15px">
        {{offering.offeringListing!.description}}
      </div>
    </div>

    <div fxLayout="row">
      <div class="grey-card p-3" fxLayout="column" fxLayoutGap="15px" fxFlex="50">
        <span class="heading-regular">Industries</span>
        <div *ngFor="let category of displayOfferingCategories()"
             class="industry-wrapper"
             fxLayout="row" fxLayoutGap="10px">
          <mat-icon class="material-icons-outlined">local_offer</mat-icon>
          <span class="heading-regular">{{category.name}}</span>
        </div>
        <div class="body-1-regular-2 btn-cursor" (click)="shortenCategories = !shortenCategories" *ngIf="showMoreLabel != ''">
          {{showMoreLabel}}
        </div>
      </div>

      <div class="grey-card p-3" fxLayout="column" fxLayoutGap="15px" fxFlex="50">
        <span class="heading-regular">Trading Hours</span>
        <div *ngFor="let day of getTradingHours()" class="time-range-wrapper" fxLayout="row"
             fxLayoutGap="10px" fxLayoutAlign="space-between center">
          <div class="time-range-label">
            <mat-icon class="material-icons-outlined">
              schedule
            </mat-icon>
            <span class="heading-regular">{{day.name}}</span>
          </div>
          <span class="heading-regular">{{displayTimeRange(day)}}</span>
        </div>
      </div>
    </div>

    <div class="grey-card py-3" fxLayout="column" fxLayoutGap="15px">
      <div class="px-3" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
        <div class="full-width" fxLayout="row" fxLayoutAlign="space-between start">
          <div class="heading-regular">Location</div>
          <div class="icon-text-container btn-cursor" fxLayoutGap="10px" (click)="openMapInNewTab()">
            <mat-icon class="material-icons-outlined">map</mat-icon>
            <span>Open in App</span>
          </div>
        </div>
        <div class="body-1-regular-1" style="text-overflow: ellipsis">{{offering.offeringAddress}}</div>
      </div>

      <div [formGroup]="addressForm" class="full-width" fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start center">
        <google-map id="googleMap" [center]="{lat: latitude, lng: longitude}" [zoom]="zoom">
          <map-marker [position]="{lat: latitude, lng: longitude}"
          [options]="{ draggable: false }"></map-marker>
        </google-map>
      </div>
    </div>

  </div>
</div>
